.App {
  text-align: center;
  font-family: 'Tahoma';

}

.Body {
  font-family: 'Tahoma';

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Tahoma';
  background: rgb(1,82,73);
  background: radial-gradient(circle, rgba(1,82,73,1) 0%, rgba(87,188,144,1) 69%, rgba(119,201,212,1) 100%);
  box-shadow: 0 5px 0 0 rgba(165, 165, 175, .1)
}

.App-link {
  color: #93c178;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.date-on-right .jobDateRange{
  text-align: left

}
